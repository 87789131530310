import { motion } from "framer-motion";
import React, { FC } from "react";
import firstBlogImage from "../../../../images/firstBlogImage.jpg";
import { ImageUrlCheck } from "../../../Shared/ImageUrlCheck/ImageUrlCheck";
import { Link } from "gatsby";
import { checkUrl } from "../../../Shared/HelperFunctions/HelperFunctions";

type Props = {
  imageClassName: string;
  imageName: string | null;
  rawImage: any;
  pathToBlog: string;
  title: string;
  alt: string;
};
const BlogArticleImage: FC<Props> = ({
  imageClassName,
  imageName = firstBlogImage,
  rawImage,
  pathToBlog,
  title,
  alt,
}) => {
  return (
    <Link
      to={`${
        pathToBlog && pathToBlog.length
          ? `${pathToBlog}/${checkUrl(title)}`
          : `#`
      }`}
    >
      <motion.div
        initial={{
          visibility: "hidden",
          opacity: 0.4,
        }}
        animate={{
          visibility: "visible",
          opacity: 1,
        }}
        transition={{ ease: "easeOut", duration: 1 }}
        className={imageClassName}
      >
        {rawImage && imageName.length && (
          <motion.img src={ImageUrlCheck(rawImage)} alt={alt} />
        )}
      </motion.div>
    </Link>
  );
};

export default BlogArticleImage;
