import React, { FC } from "react";
import * as styles from "./BlogArticle.module.scss";
import buttonArrowRight from "../../../../images/buttonArrowRight.svg";
import { Link } from "gatsby";
import { checkUrl } from "../../../Shared/HelperFunctions/HelperFunctions";
import { UseWindowSize } from "../../../hooks/UseWindowSize";
import moment from "moment";
import { ImageUrlCheck } from "../../../Shared/ImageUrlCheck/ImageUrlCheck";

type Props = {
  title: string;
  description: string;
  pic: string;
  dateBlog: any;
  onBlogHover: (x: object) => void;
  pathToBlog?: string;
  alt: string;
  type?: string;
  language?: string;
};

const BlogArticle: FC<Props> = ({
  title,
  description,
  dateBlog,
  pic,
  onBlogHover,
  pathToBlog,
  alt,
  type,
  language,
}) => {
  const { width } = UseWindowSize();
  return (
    <Link
      to={`${
        pathToBlog && pathToBlog.length && `${pathToBlog}/${checkUrl(title)}`
      }`}
    >
      <div
        className={styles.blogArticleContainer}
        onMouseEnter={() =>
          onBlogHover
            ? onBlogHover({ image: pic, pathToBlog, title, alt })
            : undefined
        }
      >
        {width < 600 && (
          <div>
            <img
              className={styles.blogCoverImage}
              src={ImageUrlCheck(pic)}
              alt={alt}
            />
          </div>
        )}
        <div className={styles.dateAndArrowBlog}>
          <span>{moment(dateBlog).format("MMM Do YYYY")}</span>
          <div className={styles.blogRightArrow}>
            <img src={buttonArrowRight} alt="open blog" />
          </div>
        </div>
        <div>
          <div>
            <h2>
              <span>.</span>
              {title}
            </h2>
          </div>
          <p>{description}</p>
          {type ? (
            <p>
              <i style={{ color: "#00ecbd", paddingRight: "10px" }}>
                {type.toLocaleUpperCase()}{" "}
              </i>
              {language ? language + " " : ""}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </Link>
  );
};

export default BlogArticle;
